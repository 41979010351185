import React from "react";
import { Outlet, useParams } from "react-router-dom";
import MainApNav from "./profile-tab-nav";

function Profile() {
  let { id } = useParams();
  return (
    <div>
      <MainApNav userName={id} />
      <div className="container mt-5">
        <Outlet context={id} />
      </div>
    </div>
  );
}
export default Profile;
