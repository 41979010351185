import React, { useEffect, useState } from "react";
import "firebase/auth";
import "../firebase";
import world from "./world";
import AutoComplete from "./auto-complete";
import SaveAndContinue from "./save-and-continue-btns";
import { useOutletContext } from "react-router-dom";

const Signup = () => {
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [description, setDescription] = useState("");
  const [standOut, setStandOut] = useState("");
  const [artistOrBandName, setArtistOrBandName] = useState("");
  const [outletUrl, setOutletUrl] = useOutletContext();
  const countries = world.country;

  useEffect(() => {
    setOutletUrl(window.location.href);
  }, [setOutletUrl]);

  return (
    <form className="onbd__form">
      <div>
        <section className="onbd__hero">
          <h2 className="onbd__hero__h1">About You!</h2>
          <p className="onbd__hero__p">
            Please tell us a little about you. You can always save your progress
            and continue later.
          </p>
        </section>
        <div className="aboutyou__container--flex">
          <label className="onbd__label">Artist / Band Name:</label>
          <input
            className="onbd__input"
            placeholder=""
            type="username"
            onChange={(e) => setArtistOrBandName(e.target.value)}
          />
        </div>
        <div className="aboutyou__container--flex">
          <label className="onbd__label">
            In which Country are you currently based?
          </label>
          <AutoComplete suggestions={countries} />
        </div>
        <div className="aboutyou__container--flex">
          <label className="onbd__label">Address:</label>
          <input
            className="onbd__input"
            type="text"
            onChange={(e) => setAddress(e.target.value)}
            placeholder=""
            required
          />
        </div>
        <div className="aboutyou__container--flex">
          <section className="">
            <label className="onbd__label">City</label>
            <input
              className="onbd__input"
              type="text"
              onChange={(e) => setCity(e.target.value)}
              required
            />
          </section>
          <section className="">
            <label className="onbd__label">Post Code</label>
            <input
              className="onbd__input"
              type="number"
              placeholder=""
              onChange={(e) => setZipCode(e.target.value)}
              required
            />
          </section>
        </div>
        <section className="aboutyou__container--flex">
          <label className="onbd__label">Description</label>
          <input
            className="onbd__input"
            type="text"
            placeholder=""
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </section>
        <section className="aboutyou__container--flex">
          <label className="onbd__label">What makes you stand out?</label>
          <input
            className="onbd__input"
            type="text"
            placeholder=""
            onChange={(e) => setStandOut(e.target.value)}
            required
          />
        </section>
      </div>
      <SaveAndContinue
        endpoint={"artist/aboutYou"}
        info={{
          address,
          city,
          zipCode,
          description,
          standOut,
          artistOrBandName,
        }}
        navigate={"musicprofile"}
      />
    </form>
  );
};

export default Signup;
