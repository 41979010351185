import firebase from 'firebase/app';
import 'firebase/firestore';
require('dotenv').config();

const firebaseConfig = {
    apiKey: "AIzaSyAn6cBytgpaMr1RrPbvHx6Dyn_7jO_NHks",
    authDomain: "gigtor-73481.firebaseapp.com",
    databaseURL: "https://gigtor-73481.firebaseio.com",
    projectId: "gigtor-73481",
    storageBucket: "gigtor-73481.appspot.com",
    messagingSenderId: "265795615471",
    appId: "1:265795615471:web:72dfd9701f1a069e1c791a"
};

export default firebaseConfig;

export const firebaseInitialize = firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
