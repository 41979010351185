import { createSlice } from '@reduxjs/toolkit';

export const statusSlice = createSlice({
    name: 'status',
    initialState: {
        value: "Status",
    },
    reducers: {
        selectStatus: (state) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.value += ''
        },
    },
})

// Action creators are generated for each case reducer function
export const { selectStatus } = statusSlice.actions

export default statusSlice.reducer