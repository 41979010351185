import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";
import Payments from "./requirements-tab-data/payment";
import Members from "./requirements-tab-data/members";
import Accomodation from "./requirements-tab-data/accomodation";
import Catering from "./requirements-tab-data/catering";
import GigDeals from "./requirements-tab-data/gig-deals";
import { useLocation } from "react-router-dom";

//place in external file at some point
const Tabs = ["Payment", "Members", "Accomodation", "Catering", "Gig Deals"];
// const bandSpecific = ["Tech Rider", "Stage Plan", "Backstage"];

function APRequirements() {
  const userName = useOutletContext();
  const [activeTab, setActiveTab] = useState(Number);

  const location = useLocation();
  let bgColor = "#111111";

  if (location.pathname === "/:id") {
    bgColor = bgColor;
  }

  function handleTabClick(index, val) {
    setActiveTab(index);
  }

  return (
    <div className="ap_req col-11">
      <style jsx="true">{`
        body {
          background: ${bgColor};
        }
      `}</style>
      <section>
        <h1>Gig Requirements</h1>
        <section className="ap-req-tab-bar d-flex flex-row align-items-center p-1">
          {Tabs.map((val, index) => {
            return activeTab === index ? (
              <button
                className="cursor-pointer border-0 bg-transparent text-white active-tab mx-2 p-2 "
                onClick={() => handleTabClick(index, val)}
                key={index}
              >
                {val}
              </button>
            ) : (
              <button
                className="cursor-pointer border-0 bg-transparent text-white mx-2"
                onClick={() => handleTabClick(index, val)}
                key={index}
              >
                {val}
              </button>
            );
          })}
        </section>

        <section>
          {activeTab === 0 ? <Payments /> : ""}
          {activeTab === 1 ? <Members /> : ""}
          {activeTab === 2 ? <Accomodation /> : ""}
          {activeTab === 3 ? <Catering /> : ""}
          {activeTab === 4 ? <GigDeals /> : ""}
        </section>
      </section>
    </div>
  );
}

export default APRequirements;
