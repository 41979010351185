import React from "react";
import "./sass/App.scss";
import LandingPage from "./landingpage/landingpage";
function App() {
  return (
    <div className="App">
      <LandingPage />
    </div>
  );
}

export default App;
