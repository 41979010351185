import React from "react";
// import genre from "./genere";

function Card(props) {
    return (
        <div className="genre__list">
            {props.gigtorGenreList}
        </div>
    )
}

export default Card