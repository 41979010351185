import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firebase';
import '../firebase';
import { db } from '../firebase';



const Signup = ({ history }) => {



	let navigate = useNavigate();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [terms, setTerms] = useState(false);
	const [errorMessage, setErrorMessage] = useState({});
	const [userName, setUserName] = useState("");

	const handleErrors = useCallback(
		() => {
			if (terms === false) {
				setErrorMessage((prevState) => ({
					...prevState,
					termMessage: 'Please check the terms of condition'
				}));
			} else {
				delete errorMessage.termMessage;
			}

			if (password !== confirmPassword) {
				setErrorMessage((prevState) => ({
					...prevState,
					passwordMessage: 'Password Does not match'
				}));
			} else {
				delete errorMessage.passwordMessage;
			}

			if (password.length < 6 && password !== '') {
				setErrorMessage((prevState) => ({
					...prevState,
					passwordLengthMessage:
						'Password Length must be at least 6 characters'
				}));
			} else {
				delete errorMessage.passwordLengthMessage;
			}
		},
		[confirmPassword, errorMessage.passwordLengthMessage, errorMessage.passwordMessage, errorMessage.termMessage, password, terms],
	);

	const handleForm = async (e) => {
		e.preventDefault();
		handleErrors();
    const userNameCheck = await db.collection("artist").doc(userName).get();
    if (userNameCheck.exists) {
      return false;
    }
		try {
			if (Object.keys(errorMessage).length <= 0 && terms === true) {
				firebase
					.auth()
					.createUserWithEmailAndPassword(email, password)
					.then((res) => {
						firebase.auth().currentUser.sendEmailVerification();
						firebase
							.auth()
							.onAuthStateChanged(async function (user) {
								if (user) {
									let authToken = firebase.auth().currentUser.getIdToken();
									localStorage.setItem('user', JSON.stringify(user));
									localStorage.setItem('authToken', authToken.i);
                  localStorage.setItem("username", userName);
								}
							});
					})
					.then(() => {
						navigate('/onboard/thankyou');
					});
			}
		} catch {
			setErrorMessage((prevState) => ({
				...prevState,
				otherError: e.message
			}));
		}
	};

	useEffect(() => {
		handleErrors();
	}, [email, password, confirmPassword, terms, handleErrors]);




	return (
		<form onSubmit={handleForm} className='onbd__form'>
			<section className='onbd__hero'>
				<h1 className='onbd__hero__h1'>Welcome to Gigtor!</h1>
				<p className='onbd__hero__p'>Please register and follow easy steps to create your profile. </p>
			</section>
			<div className='signup__container--flex'>
				<label className="onbd__label">Username</label>
				<input
					className='onbd__input'
					placeholder=''
					type="username"
					onChange={e => setUserName(e.target.value)}
				/>
			</div>
			<div className='signup__container--flex'>
				<label className="onbd__label">Email</label>
				<input
					className='onbd__input'
					type='email'
					onChange={(e) => setEmail(e.target.value)}
					placeholder=''
					required
				/>
			</div>
			<div className='signup__container--flex'>
				<label className="onbd__label">Password</label>
				<input
					className='onbd__input'
					type='password'
					onChange={(e) =>
						setPassword(e.target.value)
					}
					placeholder=''
					required
				/>
			</div>
			{errorMessage.passwordLengthMessage ? (
				<p >
					{errorMessage.passwordLengthMessage}
				</p>
			) : (
				''
			)}
			<div className='signup__container--flex'>
				<label className="onbd__label">Confirm Password</label>
				<input
					className='onbd__input'
					type='password'
					placeholder=''
					onChange={(e) =>
						setConfirmPassword(e.target.value)
					}
					required
				/>
			</div>
			{errorMessage.passwordMessage &&
				confirmPassword.length > 0 ? (
				<p className='text-center text-sm text-red-500 mt-2'>
					{errorMessage.passwordMessage}
				</p>
			) : (
				''
			)}
			<div className='sign__up__tc'>
				<label htmlFor='radio' className='sign__up__tc--label'>
					<input
						onClick={() => setTerms(!terms)}
						type='checkbox'
						id='radio'
						required
						className='sign__up__tc--input'
					/>
					&nbsp; I agree with{' '}
					<a href='/www.test.gigtor.com' className='sign__up__tc--a'>Terms & Conditions</a>
				</label>
			</div>
			<section className="get__started--center">
				<button
					type='submit'
					className='get__started__btn'
				>
					Get Started
				</button>
				<Link to="" className='has__account'>Already have an Account?<span className=''>Login</span>here</Link>
			</section>
		</form>
	);
};

export default Signup;
