import React, { useEffect, useState } from "react";
import Spotify from "../global-assets/social-icon/spotify.png";
import Apple from "../global-assets/social-icon/apple-music.png";
import SoundCloud from "../global-assets/social-icon/soundcloud.png";
import Youtube from "../global-assets/social-icon/youtube.png";
import Facebook from "../global-assets/social-icon/facebook.png";
import Instagram from "../global-assets/social-icon/instagram.png";
import SocialInput from "./social-input";
import Twitter from "../global-assets/social-icon/twitter.png";
import BandsInTown from "../global-assets/social-icon/bands-in-town.png";
import SaveAndContinue from "./save-and-continue-btns";
import { useOutletContext } from "react-router-dom";

const OnlineAppearance = ({ history }) => {
  const [spotifyURL, setSpotifyURL] = useState("");
  const [appleURL, setAppleURL] = useState("");
  const [soundCloudURL, setSoundCloudURL] = useState("");
  const [youtubeURL, setYoutubeURL] = useState("");
  const [facebookURL, setFacebookURL] = useState("");
  const [instagramURL, setInstagramURL] = useState("");
  const [twitterURL, setTwitterURL] = useState("");
  const [bandsInTownURL, setBandsInTownURL] = useState("");
  const [tikTokURL, setTikTokURL] = useState("");
  const [bandcampURL, setBandcampURL] = useState("");
  const [outletUrl, setOutletUrl] = useOutletContext();

  useEffect(() => {
    setOutletUrl(window.location.href);
  }, [setOutletUrl]);

  return (
    <section className="onbd__form">
      <div>
        <div>
          <div>
            <form>
              <div className="">
                <section className="">
                  <h2 className="social__header">Social Media !</h2>
                  <p className="social__subtitle">
                    Please connect all your social media account to show your
                    online presence.
                  </p>
                </section>
                <SocialInput
                  brand={Spotify}
                  name={"Spotify"}
                  value={spotifyURL}
                  onChange={(e) => setSpotifyURL(e)}
                />
                <SocialInput
                  brand={Apple}
                  name={"Apple"}
                  value={appleURL}
                  onChange={(e) => setAppleURL(e)}
                />
                <SocialInput
                  brand={SoundCloud}
                  name={"Soundcloud"}
                  value={soundCloudURL}
                  onChange={(e) => setSoundCloudURL(e)}
                />
                <SocialInput
                  brand={Youtube}
                  name={"Youtube"}
                  value={youtubeURL}
                  onChange={(e) => setYoutubeURL(e)}
                />
                <SocialInput
                  brand={Facebook}
                  name={"Facebook"}
                  value={facebookURL}
                  onChange={(e) => setFacebookURL(e)}
                />
                <SocialInput
                  brand={Instagram}
                  name={"Instagram"}
                  value={instagramURL}
                  onChange={(e) => setInstagramURL(e)}
                />
                <SocialInput
                  brand={Twitter}
                  name={"Twitter"}
                  value={twitterURL}
                  onChange={(e) => setTwitterURL(e)}
                />
                <SocialInput
                  brand={BandsInTown}
                  name={"BandsInTown"}
                  value={bandsInTownURL}
                  onChange={(e) => setBandsInTownURL(e)}
                />
                <SocialInput
                  brand={Spotify}
                  name={"TikTok"}
                  value={tikTokURL}
                  onChange={(e) => setTikTokURL(e)}
                />
                <SocialInput
                  brand={Spotify}
                  name={"Bandcamp"}
                  value={bandcampURL}
                  onChange={(e) => setBandcampURL(e)}
                />
                <section></section>
              </div>
              <SaveAndContinue
                endpoint="artist/socialMedia"
                info={{
                  spotifyURL,
                  appleURL,
                  soundCloudURL,
                  youtubeURL,
                  facebookURL,
                  instagramURL,
                  twitterURL,
                  bandsInTownURL,
                  tikTokURL,
                  bandcampURL,
                }}
                navigate="requirements"
              />
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OnlineAppearance;
