import React from "react";
import AppStats from "./app-stats";

function Send() {
  return (
    //Add on sumbit attribute to form
    <>
      <AppStats />
    </>
  );
}
export default Send;
