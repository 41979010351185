import React from "react";
import SideNav from "./side-nav";
import MainApNav from "./profile-tab-nav";
import { Outlet, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

function ArtistPortfolio() {
  const { id } = useParams();
  const location = useLocation();
  let bgColor = `#111111`;

  if (location.pathname === "/:id") {
    bgColor = bgColor;
  }

  return (
    <div className="ap_main container-fluid min-100vh">
      <style jsx="true">{`
        body {
          background: ${bgColor};
        }
      `}</style>
      <div className="row">
        <SideNav />
        <div className="col">
          <MainApNav userName={id} />
          <Outlet />
          {location.pathname === "/testingUser" && (
            <div className="opening-text container d-flex justify-content-center align-items-center">
              <p className="pe-3">Welcome! We are glad to see you</p>
              <img
                src="https://emojipedia-us.s3.amazonaws.com/source/noto-emoji-animations/344/smiling-face-with-sunglasses_1f60e.gif"
                srcset="https://emojipedia-us.s3.amazonaws.com/source/noto-emoji-animations/344/smiling-face-with-sunglasses_1f60e.gif 2x"
                alt="Smiling Face with Sunglasses on Noto Color Emoji, Animated 14.0"
                width="120"
                height="120"
              ></img>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ArtistPortfolio;
