import * as React from "react";
import firebase from "firebase/app";
import "firebase/auth"

// const authContext = React.createContext();

export default function useAuth() {
  const [authed, setAuthed] = React.useState(false);

  React.useEffect(() => {
    let user = firebase.auth().currentUser;
    setAuthed(user);
  }, [authed]);

  return {
    authed
  };
}

// export function AuthProvider({ children }) {
//   const auth = useAuth();

//   return (
//     <authContext.Provider value={auth}>
//       {children}
//     </authContext.Provider>
//   );
// }

// export default function AuthConsumer() {
//   return React.useContext(authContext);
// }