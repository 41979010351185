import React, { useEffect, useState } from "react";
import "firebase/auth";
import "../firebase";
import genres from "./genre";
import Card from "./card";
import SaveAndContinue from "./save-and-continue-btns";
import { useOutletContext } from "react-router-dom";

const MusicProfile = () => {
  const [searchField, setSearchField] = useState("");
  // const [genreList, setGenreList] = useState(genres);
  const [artistGenre, setArtistGenre] = useState(null);
  const [profilePicture, setProfilePicture] = useState(null);
  const [imgData, setImgData] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [outletUrl, setOutletUrl] = useOutletContext();
  const gigtorMainGenreList = genres.main;

  const uploadImage = (e) => {
    if (e.target.files[0]) {
      setProfilePicture(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
    // if (profilePicture) {
    //   return <img src={profilePicture} alt="a preview of your profile" />
    // }
    // return "Please put your profile picture"
  };
  const filteredMainGenres = gigtorMainGenreList.filter((listen) => {
    return listen.toLowerCase().includes(searchField.toLowerCase());
  });

  const stickyIcky = (index, item) => {
    setActiveItem(index);
    setArtistGenre(item);
  };

  const gigtorGenreList = filteredMainGenres.map((item, index) => {
    return (
      <div className="genre__grid" key={index.toString()}>
        <button
          className={
            index === activeItem
              ? "sticky__genre--active main__card"
              : "main__card"
          }
          onClick={() => stickyIcky(index, item)}
        >
          {item}
        </button>
      </div>
    );
  });

  const handleChange = (e) => {
    setSearchField(e.target.value);
  };

  useEffect(() => {
    setOutletUrl(window.location.href);
  }, []);

  return (
    <section>
      <div>
        <div>
          <div>
            <form className="onbd__form">
              <div>
                <section className="onbd__hero">
                  <h1 className="onbd__hero__h1">Music Profile!</h1>
                  <p className="onbd__hero__p">
                    Great! You made it to the next step. Now please give us some
                    information about your music profile so we can your artist
                    portfolio.
                  </p>
                </section>
                <div>
                  <label className="music__profile__label">
                    Which category best describes you?
                  </label>
                  <input
                    className="onbd__input"
                    placeholder="Search your genre"
                    type="text"
                    onChange={handleChange}
                  />
                  <Card gigtorGenreList={gigtorGenreList} />
                  {/* THIS WILL BE IMPLEMENTED LATER ONCE WE FIGURE SOME SHIT OUT */}
                  {/* <section className="browse__more">
                                        <button className='browse__more__btn'>Browse More Genres</button>
                                    </section> */}
                </div>
                <section className="music__upld">
                  <h2 className="music__upld__title">
                    Upload your profile picture
                  </h2>
                  <div className="upld__pic__container--flex">
                    <div className="upld__pic__container">
                      <div>
                        <label className="upld__pic">
                          <input
                            type="file"
                            onChange={(e) => uploadImage(e)}
                            required
                          />
                          <span>Upload</span>
                        </label>
                      </div>
                    </div>
                    <div className="">
                      <img
                        className="preview__image"
                        src={imgData}
                        alt="preview of the profile"
                      />
                    </div>
                  </div>
                </section>
              </div>

              <SaveAndContinue
                endpoint="artist/musicProfile"
                info={{
                  artistGenre,
                  profilePicture,
                }}
                navigate="socialmedia"
              />
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MusicProfile;
