import React from 'react';
import { useNavigate } from "react-router-dom";
// import APICall from '../api-endpoints';
import { config } from "../config.js"
import axios from 'axios';

function SaveAndContinue(props) {

  const navigate = useNavigate();
  const uploadMusicProfile = () => {
    const userName = localStorage.getItem("username");
    const formData = new FormData();
    formData.append("profilePicture", props.info.profilePicture);
    formData.append("genre", props.info.card);
    formData.append("userName", userName);
    axios.post(`${config.API_ENDPOINT}/${props.endpoint}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
  const handleSubmit= async (e) => {
    e.preventDefault();
    if (props.info.artistOrBandName) {
      localStorage.setItem("artistOrBandName", props.info.artistOrBandName);
    }
    const userName = localStorage.getItem("username");
    // let user = localStorage.getItem("user")
    props.info.userName = userName
    if (props.endpoint === "artist/musicProfile") {
      // await uploadMusicProfile();
      navigate(`/onboard/${props.navigate}`);    
    } else {
      // await APICall.artistOnboarding(props.endpoint, props.info);
      navigate(`/onboard/${props.navigate}`);
    }
    
    
  }
    return (
        <div>
            <section className="onbd__btn onbd__form">
                <button
                    type='submit'
                    className='onbd__btn__se'
                    onClick={() => handleSubmit(props.info)}
                >
                    Save and Exit
                </button>
                <button
                    type='submit'
                    className='onbd__btn__sc'
                    onClick={e => handleSubmit(e)}
                >
                    Save and Continue
                </button>
            </section>
        </div>
    )
}

export default SaveAndContinue;