import React from "react";
import LogoWithText from "../global-assets/header-logo-with-text.svg";
import { ThemeContext, themes } from "../themeContext";
import { Link } from "react-router-dom";

function SideNav() {
  const [darkMode, setDarkMode] = React.useState(true);

  return (
    <div className="col-md-3 col-lg-2 gig-border-light">
      <ul className="ap__nav">
        <li className="ap_logo">
          <img src={LogoWithText} alt="logo"></img>
        </li>
        <Link to="general" className="side-nav-hover">
          <li className="ap__nav__item">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Iconly/Bold/Profile">
                <g id="Profile">
                  <path
                    id="Profile_2"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.7347 5.80577C13.7347 8.10373 11.8923 9.94632 9.59268 9.94632C7.29389 9.94632 5.45063 8.10373 5.45063 5.80577C5.45063 3.50782 7.29389 1.66602 9.59268 1.66602C11.8923 1.66602 13.7347 3.50782 13.7347 5.80577ZM9.59251 17.314C6.19872 17.314 3.33325 16.7624 3.33325 14.6342C3.33325 12.5053 6.21672 11.9732 9.59251 11.9732C12.9871 11.9732 15.8518 12.5248 15.8518 14.653C15.8518 16.7819 12.9683 17.314 9.59251 17.314Z"
                    fill=""
                  />
                </g>
              </g>
            </svg>
            Profile
          </li>
        </Link>
        <Link to="account" className="side-nav-hover">
          <li className="ap__nav__item">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.0016 11.2702C17.2997 11.4229 17.5296 11.664 17.6914 11.905C18.0066 12.4032 17.981 13.014 17.6744 13.5523L17.0783 14.5166C16.7632 15.0309 16.1755 15.3523 15.5709 15.3523C15.2728 15.3523 14.9407 15.272 14.6682 15.1113C14.4467 14.9747 14.1912 14.9265 13.9187 14.9265C13.0756 14.9265 12.3688 15.5934 12.3432 16.389C12.3432 17.3131 11.5597 18.0363 10.5803 18.0363H9.42211C8.43422 18.0363 7.65072 17.3131 7.65072 16.389C7.63369 15.5934 6.92683 14.9265 6.08372 14.9265C5.80268 14.9265 5.54719 14.9747 5.33428 15.1113C5.06176 15.272 4.72111 15.3523 4.43155 15.3523C3.81838 15.3523 3.23075 15.0309 2.91565 14.5166L2.32802 13.5523C2.01292 13.03 1.99589 12.4032 2.31099 11.905C2.44725 11.664 2.70274 11.4229 2.9923 11.2702C3.23075 11.1577 3.38405 10.9729 3.52882 10.7559C3.95464 10.0648 3.69915 9.15681 2.97526 8.74699C2.13215 8.28895 1.85963 7.26842 2.34506 6.47288L2.91565 5.52467C3.4096 4.72913 4.46562 4.44788 5.31725 4.91395C6.05817 5.29967 7.02051 5.04252 7.45484 4.35949C7.5911 4.13449 7.66775 3.89342 7.65072 3.65234C7.63369 3.33895 7.72737 3.04163 7.88918 2.80056C8.20428 2.30234 8.77487 1.98092 9.39656 1.96484H10.5974C11.2276 1.96484 11.7982 2.30234 12.1133 2.80056C12.2666 3.04163 12.3688 3.33895 12.3432 3.65234C12.3262 3.89342 12.4028 4.13449 12.5391 4.35949C12.9734 5.04252 13.9358 5.29967 14.6852 4.91395C15.5283 4.44788 16.5928 4.72913 17.0783 5.52467L17.6489 6.47288C18.1428 7.26842 17.8703 8.28895 17.0187 8.74699C16.2948 9.15681 16.0393 10.0648 16.4736 10.7559C16.6099 10.9729 16.7632 11.1577 17.0016 11.2702ZM7.591 10.0088C7.591 11.2704 8.67257 12.2749 10.0096 12.2749C11.3467 12.2749 12.4027 11.2704 12.4027 10.0088C12.4027 8.74719 11.3467 7.73469 10.0096 7.73469C8.67257 7.73469 7.591 8.74719 7.591 10.0088Z"
                fill=""
              />
            </svg>
            Account
          </li>
        </Link>
        <Link to="send" className="side-nav-hover">
          <li className="ap__nav__item">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.2148 2.34154C16.8146 1.9455 16.2224 1.79795 15.6702 1.95326L2.7929 5.56421C2.21026 5.7203 1.79729 6.16837 1.68605 6.73758C1.5724 7.31688 1.96936 8.05227 2.48798 8.35978L6.51442 10.7461C6.92739 10.9907 7.46041 10.9294 7.80215 10.597L12.4128 6.12333C12.6449 5.89036 13.0291 5.89036 13.2612 6.12333C13.4933 6.34852 13.4933 6.7135 13.2612 6.94647L8.6425 11.4209C8.29995 11.7525 8.23593 12.2689 8.48803 12.6696L10.9482 16.5912C11.2364 17.0571 11.7326 17.3211 12.2768 17.3211C12.3408 17.3211 12.4128 17.3211 12.4769 17.3134C13.1011 17.2357 13.5973 16.8242 13.7814 16.2417L17.599 3.84027C17.767 3.31222 17.615 2.73758 17.2148 2.34154Z"
                fill=""
              />
            </svg>
            Send
          </li>
        </Link>
        <ThemeContext.Consumer>
          {({ changeTheme }) => (
            <li
              className="ap__nav__item"
              id="ap-dark-light"
              color="link"
              onClick={() => {
                setDarkMode(!darkMode);
                changeTheme(darkMode ? themes.light : themes.dark);
              }}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C19.9939 15.5203 15.5203 19.9939 10 20ZM9.984 18H10V2C5.58172 1.99558 1.99642 5.57372 1.992 9.992C1.98758 14.4103 5.56572 17.9956 9.984 18Z"
                  fill=""
                />
              </svg>
              Dark / Light
            </li>
          )}
        </ThemeContext.Consumer>
      </ul>
    </div>
  );
}

export default SideNav;
