import React from "react";
import { useNavigate } from "react-router-dom";


function Thankyou() {
  const navigate = useNavigate();
  const goToNextPage = () => {
    navigate('/onboard/aboutyou')
  }
  return (
      <div className="thankyou--main">
          <section className="thankyou--hero">
              <h1 className="thankyou--heading">Thank you for creating your account
                  with Gigtor!</h1>
          </section>
          <section className="thankyou--content">
              <p className="thankyou--content--inner">We have just sent you an email with a confirmation link. Meanwhile why don't you add something to your new profile!</p>
          </section>
          <section className="">
              <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                  <circle className="checkmark--circle" cx="26" cy="26" r="25" fill="none" />
                  <path className="checkmark--check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
              </svg>
          </section>
          <section className="thankyou--btn--container">
                  {/* <Link to="/profile" className="thankyou--btn">Edit Your Profile</Link> */}
            <button 
              className="thankyou--btn" 
              onClick={() => goToNextPage()}>Edit Your Profile</button>
          </section>
      </div>
  )
}

export default Thankyou;