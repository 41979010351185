import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { config } from "../config";
import GuitarPlayer from "../global-assets/desktop-guitar-player.png";
import Logo from "../global-assets/header-logo-with-text.svg";

function OnboardingApp() {

  const [colorFill] = useState('completed');
  const [outletUrl, setOutletUrl] = useState(`${config.host_url_testing}/onboard/signup`);


  const renderStepProgress = () => {
    switch (outletUrl) {
      case `${config.host_url_testing}/onboard/aboutyou`:
        return (
          <div>
            <div className="stepper-wrapper">
              <div className={"stepper-item active " + colorFill}>
                <div className="step-counter"></div>
                <div className="step-name" id="create__account"><p>Create Account</p></div>
              </div>

              <div className="stepper-item">
                <div className="step-counter"></div>
                <div className="step-name"><p>About You</p></div>
              </div>

              <div className="stepper-item">
                <div className="step-counter"></div>
                <div className="step-name"><p>Music Profile</p></div>
              </div>

              <div className="stepper-item active">
                <div className="step-counter"></div>
                <div className="step-name">
                  <p>Social Media</p>
                </div>
              </div>
              <div className="stepper-item">
                <div className="step-counter"></div>
                <div className="step-name" id="requirements">
                  <p>Requirements</p>
                </div>
              </div>
            </div>
          </div>
        );
      case `${config.host_url_testing}/onboard/musicprofile`:
        return (
          <div>
            <div className="stepper-wrapper">
              <div className={"stepper-item " + colorFill}>
                <div className="step-counter"></div>
                <div className="step-name" id="create__account"><p>Create Account</p></div>
              </div>

              <div className={"stepper-item " + colorFill}>
                <div className="step-counter"></div>
                <div className="step-name"><p>About You</p></div>
              </div>

              <div className="stepper-item">
                <div className="step-counter active "></div>
                <div className="step-name"><p>Music Profile</p></div>
              </div>

              <div className="stepper-item">
                <div className="step-counter"></div>
                <div className="step-name">
                  <p>Social Media</p>
                </div>
              </div>
              <div className="stepper-item">
                <div className="step-counter"></div>
                <div className="step-name" id="requirements">
                  <p>Requirements</p>
                </div>
              </div>
            </div>
          </div>
        );
      case `${config.host_url_testing}/onboard/socialmedia`:
        return (
          <div>
            <div className="stepper-wrapper">
              <div className={"stepper-item active " + colorFill}>
                <div className="step-counter"></div>
                <div className="step-name" id="create__account"><p>Create Account</p></div>
              </div>

              <div className={"stepper-item active " + colorFill}>
                <div className="step-counter"></div>
                <div className="step-name"><p>About You</p></div>
              </div>

              <div className={"stepper-item " + colorFill}>
                <div className="step-counter"></div>
                <div className="step-name"><p>Music Profile</p></div>
              </div>

              <div className="stepper-item active">
                <div className="step-counter"></div>
                <div className="step-name">
                  <p>Social Media</p>
                </div>
              </div>
              <div className="stepper-item">
                <div className="step-counter"></div>
                <div className="step-name" id="requirements">
                  <p>Requirements</p>
                </div>
              </div>
            </div>
          </div>
        );
      case `${config.host_url_testing}/onboard/requirements`:
        return (
          <div>
            <div className="stepper-wrapper">
              <div className={"stepper-item " + colorFill}>
                <div className="step-counter"></div>
                <div className="step-name" id="create__account">
                  <p>Create Account</p>
                </div>
              </div>
              <div className={"stepper-item " + colorFill}>
                <div className="step-counter"></div>
                <div className="step-name">
                  <p>About You</p>
                </div>
              </div>
              <div className={"stepper-item " + colorFill}>
                <div className="step-counter"></div>
                <div className="step-name"><p>Music Profile</p></div>
              </div>
              <div className={"stepper-item " + colorFill}>
                <div className="step-counter"></div>
                <div className="step-name">
                  <p>Social Media</p>
                </div>
              </div>
              <div className="stepper-item active">
                <div className="step-counter"></div>
                <div className="step-name" id="requirements">
                  <p>Requirements</p>
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return (
          <div>
            <div className="stepper-wrapper">
              <div className="stepper-item active">
                <div className="step-counter"></div>
                <div className="step-name" id="create__account"><p>Create Account</p></div>
              </div>

              <div className="stepper-item">
                <div className="step-counter"></div>
                <div className="step-name"><p>About You</p></div>
              </div>

              <div className="stepper-item">
                <div className="step-counter"></div>
                <div className="step-name"><p>Music Profile</p></div>
              </div>

              <div className="stepper-item active">
                <div className="step-counter"></div>
                <div className="step-name">
                  <p>Social Media</p>
                </div>
              </div>
              <div className="stepper-item">
                <div className="step-counter"></div>
                <div className="step-name" id="requirements">
                  <p>Requirements</p>
                </div>
              </div>
            </div>
          </div>
        );
    }
  };





  return (
    <div className="onbd--container">
      <img
        src={GuitarPlayer}
        alt="guitar player"
        className="onbd--guitar--player"
      />
      <img src={Logo} alt="Gigtor" className="onbd--logo" />
      {renderStepProgress(colorFill)}
      <div className="onbd--whitebox">
        <Outlet context={[outletUrl, setOutletUrl]} />
      </div>
    </div>
  );
}

export default OnboardingApp;
