const { config } = require("./config");

export const APICall = {
  artistOnboarding(endpoint, data) {
    fetch(`${config.API_ENDPOINT}/${endpoint}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          return res.json().then((error) => Promise.reject(error));
        }
        return res.json();
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.error(error);
        return error.message;
      });
  },
  MailChimpSubscriber(endpoint, data) {
    const validateEmail = () => {
      if (
        String(data.email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
      ) {
        return true;
      }
      return false;
    };

    if (validateEmail === true) {
      fetch(`${config.API_ENDPOINT}/${endpoint}`, {
        method: "POST",
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (!res.ok) {
            console.error(res.ok);
            return res.ok;
          }
          res.json();
        })
        .then(() => {})
        .catch((err) => {
          return err;
        });
    }
    return "Please enter a correct email address";
  },
  async sendEmail(endpoint, data) {
    await fetch(`${config.API_ENDPOINT}/${endpoint}`, {
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          console.error(res.ok);
          throw new Error();
        }
        res.json();
      })
      .then((data) => {
        return data;
      })
      .catch((err) => {
        return err;
      });
  },
  updateDescription(endpoint, data) {
    fetch(`${config.API_ENDPOINT}/${endpoint}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          return res.json().then((error) => Promise.reject(error));
        }
        return res.json();
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.error(error);
        return error.message;
      });
  },
  updateSocialMedia(endpoint, data) {
    fetch(`${config.API_ENDPOINT}/${endpoint}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          return res.json().then((error) => Promise.reject(error));
        }
        return res.json();
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.error(error);
        return error.message;
      });
  },
  updatePerformedIn(endpoint, data) {
    fetch(`${config.API_ENDPOINT}/${endpoint}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          return res.json().then((error) => Promise.reject(error));
        }
        return res.json();
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.error(error);
        return error.message;
      });
  },
  updateTracks(endpoint, data) {},
  updateMedia(endpoint, data) {},
};
