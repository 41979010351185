import React, { useEffect, useState } from "react";
import { config } from "../config";
import { useLocation } from "react-router-dom";
import { APICall } from "../api-endpoints.js";

function VideoPlayer(props) {
  return <video src={props.src} controls className={props.className} />;
}

function ImageViewer(props) {
  return (
    <img src={props.src} className={props.className} alt="artist covers" />
  );
}

function General() {
  const [generalData, setGeneralData] = useState([]);
  const [videoData, setVideoData] = useState([]);
  const [photoData, setPhotoData] = useState([]);
  const [tracksData, setTracksData] = useState([]);
  const [description, setDescription] = useState(
    generalData?.description ?? []
  );
  const [genre, setGenre] = useState(generalData?.genre ?? []);
  const [stageName, setStageName] = useState(generalData?.stageName ?? null);
  const [social, setSocial] = useState([]);
  const [performed, setPerformed] = useState(generalData?.performed ?? []);
  const [editSection, setEditSection] = useState(false);
  const [sectionName, setSectionName] = useState(null);

  const [activeIndex, setActiveIndex] = useState(0);

  const location = useLocation();
  let bgColor = "#111111";

  if (location.pathname === "/:id") {
    bgColor = bgColor;
  }

  const fetchData = async () => {
    const url = `${config.API_ENDPOINT}/artist/testingUser`;
    try {
      const response = await fetch(url);
      const json = await response.json();
      setGeneralData(json);
      setVideoData(json?.media?.videos);
      setPhotoData(json?.media?.images);
      setTracksData(json?.music);
      setDescription(json?.description);
      setGenre(json?.genre);
      setStageName(json?.stageName);
      setSocial([
        {
          siteName: "Spotify",
          socialSite: json?.spotify,
          socialFollowers: json?.spotifyFollowers,
        },
        {
          siteName: "Apple Music",
          socialSite: json?.appleMusic,
          socialFollowers: json?.applemusicFollowers,
        },
        {
          siteName: "Bandcamp",
          socialSite: json?.bandcamp,
          socialFollowers: json?.bandcampFollowers,
        },
        {
          siteName: "Bands In Town",
          socialSite: json?.bandsInTown,
          socialFollowers: json?.bandsintownFollowers,
        },
        {
          siteName: "Facebook",
          socialSite: json?.facebook,
          socialFollowers: json?.facebookFollowers,
        },
        {
          siteName: "Instagram",
          socialSite: json?.instagram,
          socialFollowers: json?.instagramFollowers,
        },
        {
          siteName: "SoundCloud",
          socialSite: json?.soundCloud,
          socialFollowers: json?.soundcloudFollowers,
        },
        {
          siteName: "TikTok",
          socialSite: json?.tikTok,
          socialFollowers: json?.tiktokFollowers,
        },
        {
          siteName: "Twitter",
          socialSite: json?.twitter,
          socialFollowers: json?.twitterFollowers,
        },
        {
          siteName: "Youtube",
          socialSite: json?.youtube,
          socialFollowers: json?.youtubeFollowers,
        },
      ]);
      setPerformed(json?.performed);

      // Set state with json data
      if (json.media) {
        setVideoData(json.media.videos);
        setPhotoData(json.media.images);
        setTracksData(json.music);
        setGeneralData(json.media);
      }
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    // Get artist data from local storage
    let artistData = JSON.parse(localStorage.getItem("artistData"));

    // If no artist data, fetch it
    if (artistData === null) {
      fetchData();
      return;
    }

    // Calculate the time elapsed since last checked
    let checkedDate = new Date(artistData["testingUser"].lastChecked);
    let currentTime = new Date();
    let elapsedTime = currentTime.getTime() - checkedDate.getTime();

    // If artist data exists and less than 1 hour has elapsed, set state with stored data
    if (
      (artistData["testingUser"] !== null ||
        artistData["testingUser"] !== undefined) &&
      elapsedTime < 3600000
    ) {
      setVideoData(artistData["testingUser"].media.videos);
      setPhotoData(artistData["testingUser"].media.images);
      if (artistData["testingUser"].music) {
        setTracksData(artistData["testingUser"].music);
      }
      setGeneralData(artistData["testingUser"]);
      return;
    }

    // If artist data does not exist or more than 1 hour has elapsed, fetch updated data
    fetchData();
  }, []);

  function handleEdit(sectionName) {
    setEditSection(true);
    setSectionName(sectionName);
  }

  function handleCancel() {
    setSectionName(null);
    setEditSection(false);
  }

  function handleDelete(arrayName, i) {
    if (arrayName === "performances") {
      let performanceArray = generalData?.performances;
      performanceArray.slice(i, 0);
      // might be 0, i
      // need to perform api call here
    } else if (arrayName === "tracks") {
      let tracksArray = tracksData;
      tracksArray.slice(i, 0);
      // need to perform an api call here
    } else if (arrayName === "videos") {
      let videosArray = videoData;
      videosArray.slice(i, 0);
      // need to perform an api call here
    } else if (arrayName === "photos") {
      let photosArray = photoData;
      photosArray.slice(i, 0);
      // Need to perform an api call here
    }
  }

  function handleClick(e) {
    setActiveIndex(e.target.dataset.index);
    window.scrollTo(0, 0);
  }

  function handleSave(sectionName) {
    if (sectionName === "description") {
      APICall.updateDescription("/artist/updateDescription", {
        description: description,
        country: generalData?.city,
        genre: genre,
        stageName: stageName,
      });
    } else if (sectionName === "performances") {
      APICall.updatePerformedIn("/artist/updatePerformedIn", { performed });
    } else if (sectionName === "tracks") {
      APICall.updateTracks("/artist/updateTracks", { tracks: tracksData });
    } else if (sectionName === "socialMedia") {
      APICall.updateSocialMedia("/artist/updateSocialMedia", {
        appleMusic: generalData?.appleMusic,
        appleMusicFollowers: generalData?.appleMusicFollowers,
        bandcamp: generalData?.bandcamp,
        bandcampFollowers: generalData?.bandcampFollowers,
        bandsInTown: generalData?.bandsInTown,
        bandsintownFollowers: generalData?.bandsintownFollowers,
        facebook: generalData?.facebook,
        facebookFollowers: generalData?.facebookFollowers,
        instagram: generalData?.instagram,
        instagramFollowers: generalData?.instagramFollowers,
        soundCloud: generalData?.soundCloud,
        soundcloudFollowers: generalData?.soundcloudFollowers,
        spotify: generalData?.spotify,
        spotifyFollowers: generalData?.spotifyFollowers,
        tikTok: generalData?.tikTok,
        tiktokFollowers: generalData?.tiktokFollowers,
        twitter: generalData?.twitter,
        twitterFollowers: generalData?.twitterFollowers,
        youtube: generalData?.youtube,
        youtubeFollowers: generalData?.youtubeFollowers,
      });
    } else if (sectionName === "videos") {
      APICall.updateMedia("/artist/updateMedia", {
        videos: videoData,
        newVideo: "newVideo",
        images: photoData,
        fileType: "video",
      });
    } else if (sectionName === "photos") {
      APICall.updateMedia("/artist/updateMedia", {
        videos: videoData,
        newPhoto: "newPhoto",
        images: photoData,
        fileType: "photo",
      });
    }
    setEditSection(false);
  }

  return (
    <>
      <div className="row">
        <style jsx="true">{`
          body {
            background: ${bgColor};
          }
        `}</style>
        <section className="col-6 mt-3 mb-2">
          <button
            className="edit mt-3 mb-3"
            onClick={() => handleEdit("artistInfo")}
          >
            Edit
          </button>
          {editSection && sectionName === "artistInfo" ? (
            <div>
              <form className="edit-form">
                <label htmlFor="stage-name">
                  {" "}
                  Name:
                  <input
                    name="stage-name"
                    type="text"
                    value={stageName}
                    onChange={(e) => setStageName(e.target.value)}
                  />
                </label>
                <label>
                  {" "}
                  Genre:
                  <input
                    name="genre"
                    type="text"
                    placeholder="Jazz, Blues, Folk"
                    value={genre}
                    onChange={(e) => setGenre(e.target.value)}
                  />
                </label>
                <label htmlFor="description">
                  {" "}
                  Description:
                  <textarea
                    name="description"
                    type="text"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </label>
              </form>
              <div>
                <button onClick={() => handleSave()}>Save</button>
                <button onClick={() => handleCancel()}>Cancel</button>
              </div>
            </div>
          ) : (
            <section className="bg-ap-darker br-15 py-3 px-3 mb-5">
              <h1 className="artist-name-ap">{stageName}</h1>
              <p>{genre}</p>
              <p>{description}</p>
            </section>
          )}
          {editSection && sectionName === "performances" ? (
            <div>
              <form className="edit-form">
                <label>
                  Performances:
                  <input
                    type="text"
                    placeholder="Madison Square Garden, Madison Square Garden, Madison Square Garden"
                  />
                </label>
                {performed?.map((venue, i) => {
                  return (
                    <p key={i}>
                      {venue}
                      <button onClick={() => handleDelete("performances", i)}>
                        x
                      </button>
                    </p>
                  );
                })}
              </form>
              <div>
                <button onClick={() => handleSave()}>Save</button>
                <button onClick={() => handleCancel()}>Cancel</button>
              </div>
            </div>
          ) : (
            <section className="mt-3 mb-2">
              <button
                className="edit"
                onClick={() => handleEdit("performances")}
              >
                Edit
              </button>
              <h2 className="performed mt-3">Performed</h2>
              <div className="row">
                {performed?.map((venue, i) => {
                  return (
                    <div className="col-4">
                      <p key={i}>{venue}</p>
                    </div>
                  );
                })}
              </div>
            </section>
          )}
          {editSection && sectionName === "tracks" ? (
            <div>
              <form className="edit-form">
                <input type="file" accept=".mp3" />
                {/* //forgot what to use for uploads */}
                {tracksData
                  ? tracksData?.map((val, index) => {
                      return (
                        <section
                          key={index}
                          className="row flex-row-reverse my-5 justify-content-center align-items-center"
                        >
                          <section className="col">
                            {" "}
                            <p>{val?.title}</p>
                          </section>
                          <section className="col">
                            {" "}
                            <audio controls className="audio">
                              <source src={val?.track} type="audio/mp3" />
                            </audio>
                          </section>
                          <button onClick={() => handleDelete("tracks", index)}>
                            x
                          </button>
                        </section>
                      );
                    })
                  : null}
              </form>
              <div>
                <button onClick={() => handleSave()}>Save</button>
                <button onClick={() => handleCancel()}>Cancel</button>
              </div>
            </div>
          ) : (
            <section className="mt-3 mb-2">
              <button className="edit" onClick={() => handleEdit("tracks")}>
                Edit
              </button>
              <div className="tracks-wrapper">
                <h2 className="tracks mt-3">My Tracks</h2>
                {tracksData
                  ? tracksData?.map((val, index) => {
                      return (
                        <section
                          key={index}
                          className="row flex-row-reverse my-5 justify-content-center align-items-center"
                        >
                          <section className="col">
                            {" "}
                            <p>{val?.title}</p>
                          </section>
                          <section className="col">
                            {" "}
                            <audio controls className="audio">
                              <source src={val?.track} type="audio/mp3" />
                            </audio>
                          </section>
                        </section>
                      );
                    })
                  : null}
              </div>
            </section>
          )}
          {editSection && sectionName === "socialMedia" ? (
            <div>
              <div>
                <button onClick={() => handleSave()}>Save</button>
                <button onClick={() => handleCancel()}>Cancel</button>
              </div>
              <form className="edit-form">
                <div>
                  <label>
                    Facebook Profile:
                    <input type="text" />
                  </label>
                  <label>
                    Facebook Followers:
                    <input type="number" />
                  </label>
                </div>
                <div>
                  <label>
                    Twitter Profile:
                    <input type="text" />
                  </label>
                  <label>
                    Twitter Followers:
                    <input type="number" />
                  </label>
                </div>
                <div>
                  <label>
                    Tik Tok Profile:
                    <input type="text" />
                  </label>
                  <label>
                    Tik Tok Followers:
                    <input type="number" />
                  </label>
                </div>
                <div>
                  <label>
                    Instagram Profile:
                    <input type="text" />
                  </label>
                  <label>
                    Instagram Followers:
                    <input type="number" />
                  </label>
                </div>
                <div>
                  <label>
                    Bandcamp Profile:
                    <input type="text" />
                  </label>
                  <label>
                    Bandcamp Followers:
                    <input type="number" />
                  </label>
                </div>
                <div>
                  <label>
                    Bands In Town Profile:
                    <input type="text" />
                  </label>
                  <label>
                    Bands In Town Followers:
                    <input type="number" />
                  </label>
                </div>
                {/* Need to include all the other social medias when I figure out which ones */}
              </form>
            </div>
          ) : (
            <section className="mt-3 mb-2">
              <button
                className="edit"
                onClick={() => handleEdit("socialMedia")}
              >
                Edit
              </button>
              <h3 className="tracks mt-3">Social Media</h3>
              <div className="row">
                {social
                  ? social.map((socials, index) => {
                      return (
                        <section
                          key={index}
                          className="col gig-fill-grey pr-15 p-3 br-15 me-3"
                        >
                          <p className="follower-card-copy nunito">
                            <a href={socials.socialSite}>{socials.siteName}</a>
                          </p>
                          <p className="follower-card-copy nunito">
                            {socials.socialFollowers} followers
                          </p>
                        </section>
                      );
                    })
                  : null}
              </div>
            </section>
          )}
        </section>
        <section className="col-6">
          <div className="mt-3">
            {activeIndex < videoData.length ? (
              <VideoPlayer src={videoData[activeIndex]} className="mw-100" />
            ) : (
              <ImageViewer
                src={photoData[activeIndex - videoData.length]}
                className="mw-100"
              />
            )}
          </div>
          <section className="container-fluid bg-ap-darker mt-4 br-15 gig-overflow-y media-height-inner">
            {editSection && sectionName === "video" ? (
              <div>
                <form className="edit-form">
                  <label>
                    Upload New Video:
                    <input type="file" accept="video/mp4" />
                    {/* need to figure out file upload */}
                  </label>

                  <section className="row">
                    {videoData.map((val, index) => {
                      return (
                        <section
                          id={index}
                          className="col-md-12 col-lg-4"
                          key={index}
                        >
                          <div className="d-flex flex-column">
                            <video
                              src={val}
                              className="mw-100 mh-100  media-video pb-4"
                              preload="metadata"
                            ></video>
                          </div>
                          <button onClick={() => handleDelete("videos", index)}>
                            x
                          </button>
                        </section>
                      );
                    })}
                  </section>
                </form>
                <div>
                  <button onClick={() => handleSave()}>Save</button>
                  <button onClick={() => handleCancel()}>Cancel</button>
                </div>
              </div>
            ) : (
              <section className="mt-3 mb-2">
                <button
                  className="edit mb-3"
                  onClick={() => handleEdit("video")}
                >
                  Edit
                </button>
                <h3 className="pv py-2">Videos</h3>

                <div className="row general-pointer" onClick={handleClick}>
                  {videoData.map((video, index) => (
                    <div className="col">
                      <video
                        src={video}
                        className="mw-100 mh-100 w-100 h-100  media-video pb-4"
                        preload="metadata"
                        key={index}
                        data-index={index}
                        onClick={handleClick}
                      ></video>
                    </div>
                  ))}
                </div>
              </section>
            )}
            {editSection && sectionName === "photos" ? (
              <div>
                <form className="edit-form">
                  <loabel>
                    Upload new photo:
                    <input type="file" accept="image/*" />
                  </loabel>
                  {photoData?.map((val, index) => {
                    return (
                      <section key={index} className="col">
                        <img
                          width=""
                          src={val}
                          alt=""
                          className="w-100 h-100 pb-4 media-photo"
                        ></img>
                        <button onClick={() => handleDelete("photos", index)}>
                          x
                        </button>
                      </section>
                    );
                  })}
                </form>
                <div>
                  <button onClick={() => handleSave()}>Save</button>
                  <button onClick={() => handleCancel()}>Cancel</button>
                </div>
              </div>
            ) : (
              <section>
                <h4 className="pv my-3">Photos</h4>
                <div onClick={handleClick} className="row general-pointer">
                  {photoData.map((image, index) => (
                    <div className="col col-lg-4 col-xl-5">
                      <img
                        data-index={index + videoData.length}
                        src={image}
                        alt="media"
                        className="pb-4 media-photo same-size-images"
                      ></img>
                    </div>
                  ))}
                </div>
              </section>
            )}
          </section>
        </section>
      </div>
    </>
  );
}
export default General;
