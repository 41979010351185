import React from "react";
import { Link } from "react-router-dom";

function MainApNav({ userName }) {
  return (
    <nav className="main_ap_nav">
      <ul className="main_ap_ul">
        <Link to={`/${userName}/general`}>
          <li className="ap__mainnav__item">
            General
          </li>
        </Link>
        <li></li>
        <Link to={`/${userName}/aprequire`}>
          <li className="ap__mainnav__item">
            Requirements
          </li>
        </Link>
      </ul>
    </nav>
  )
}

export default MainApNav;