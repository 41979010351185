import React from "react";
import { render } from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// OnboardingApp
import OnboardingApp from "./artist-onboarding-app/onboarding-app";
import Signup from "./artist-onboarding-app/signup";
import AboutYou from "./artist-onboarding-app/about-you";
import Thankyou from "./artist-onboarding-app/thankyou";
import MusicProfile from "./artist-onboarding-app/music-profile";
import SocialMedia from "./artist-onboarding-app/social-media";
import Requirements from "./artist-onboarding-app/requirements";

// ArtistPortolio
import ArtistPortfolio from "./artist-portfolio/artist-portfolio";
import Profile from "./artist-portfolio/profile";
import General from "./artist-portfolio/general";
import APRequirements from "./artist-portfolio/aprequirements";
import Account from "./artist-portfolio/account";
import Send from "./artist-portfolio/send";
import AppStats from "./artist-portfolio/app-stats";

// AP TAB DATA
import Payments from "./artist-portfolio/requirements-tab-data/payment";
import Members from "./artist-portfolio/requirements-tab-data/members";
import Accomodation from "./artist-portfolio/requirements-tab-data/accomodation";

//landingpage
import LandingPage from "./landingpage/landingpage";

//Firebase
import RequireAuth from "./RequireAuth";
import firebase from "firebase/app";
import "firebase/auth";
import "./firebase.js";

import { Provider } from "react-redux";
import store from "./store";

//ThemeContext
import ThemeContextWrapper from "./themeContextWrapper";

let user;
firebase.auth().onAuthStateChanged((userProfile) => {
  if (user) {
    user = userProfile;
  }
});

const rootElement = document.getElementById("root");
render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/giglp" element={<App />} />
        <Route path="/onboard" element={<OnboardingApp />}>
          <Route path="signup" element={<Signup />} />
          <Route path="thankyou" element={<Thankyou />} />
          <Route
            path="aboutyou"
            element={
              <RequireAuth>
                <AboutYou />
              </RequireAuth>
            }
          />
          <Route
            path="musicprofile"
            element={
              <RequireAuth>
                <MusicProfile />
              </RequireAuth>
            }
          />
          <Route
            path="socialmedia"
            element={
              <RequireAuth>
                <SocialMedia />
              </RequireAuth>
            }
          />
          <Route
            path="requirements"
            element={
              <RequireAuth>
                <Requirements />
              </RequireAuth>
            }
          />
        </Route>
        {/* need to add routes to for artist portolfio send, account, etc.. */}

        <Route
          path="/:id"
          element={
            <RequireAuth>
              <ThemeContextWrapper>
                <ArtistPortfolio />
              </ThemeContextWrapper>
            </RequireAuth>
          }
        >
          <Route
            path="profile"
            element={
              <RequireAuth>
                <Profile />
              </RequireAuth>
            }
          />
          <Route
            path="general"
            element={
              <RequireAuth>
                <General />
              </RequireAuth>
            }
          />
          <Route path="aprequire" element={<APRequirements />}></Route>
          <Route
            path="payments"
            element={
              <RequireAuth>
                <Payments />
              </RequireAuth>
            }
          />
          <Route
            path="members"
            element={
              <RequireAuth>
                <Members />
              </RequireAuth>
            }
          />
          <Route
            path="accomodation"
            element={
              <RequireAuth>
                <Accomodation />
              </RequireAuth>
            }
          />
          <Route
            path="account"
            element={
              <RequireAuth>
                <Account />
              </RequireAuth>
            }
          />
          <Route
            path="send"
            element={
              <RequireAuth>
                <Send />
              </RequireAuth>
            }
          />

          <Route
            path="appstats"
            element={
              <RequireAuth>
                <AppStats />
              </RequireAuth>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  </Provider>,
  rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
