import React from "react";
import { useLocation } from "react-router-dom";

function Account() {
  const location = useLocation();
  let bgColor = "#111111";

  if (location.pathname === "/:id") {
    bgColor = bgColor;
  }
  return (
    <div className="ap_account">
      <style jsx="true">{`
        body {
          background: ${bgColor};
        }
      `}</style>
      <section
        className="ap_container_fill ap_max-width"
        id="email_pass_change"
      >
        <section className="heading-width">
          <h1 className="account_header headings">Change Password and Email</h1>
        </section>
      </section>
      <section className="ap_container_fill ap_max-width" id="multi-auth">
        <section className="heading-width">
          <h2 className="account_header headings">
            Multifactor Authentication
          </h2>
        </section>
      </section>
    </div>
  );
}
export default Account;
