import React from "react";

import { Navigate } from "react-router-dom";

import useAuth from "./UseAuth.js"


import "firebase/auth"


function RequireAuth({ children }) {
  const { authed } = useAuth()

  return authed !== false || authed !== null
    ? children
    : <Navigate to="/onboard/signup" replace />;
}

export default RequireAuth;