import React from "react";

function SocialInput(props) {
  function handleChange(event) {
    // Here, we invoke the callback with the new value
    props.onChange(event.target.value);
  }

  return (
    <>
      <div className="social">
        <div className="social__icon">
          <label htmlFor={props.name}>
            <img src={props.brand} alt={`Social Media: ${props.name}`} />
          </label>
        </div>
        <div className="social__info">
          <p className="social__title">{props.name}</p>
          <input
            placeholder="Copy-paste the link to your profile here"
            type="url"
            id={props.name}
            className="onbd__input"
            onChange={handleChange}
            value={props.value}
            name={props.name}
          />
        </div>
      </div>
    </>
  );
}

export default SocialInput;
